import { Controller } from "@hotwired/stimulus"
import { useClickOutside } from "stimulus-use"
// Connects to data-controller="modal"
export default class extends Controller {
  static outlets = ['overlay']

  connect() {
    this.overlayOutlet.show()
    useClickOutside(this)
  }

  disconnect() {
    this.overlayOutlet.hide()
  }

  clickOutside() {
    this.overlayOutlet.hide()
    this.close()
  }

  close() {
    this.element.remove()
  }
}
