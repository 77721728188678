import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="snippet-form"
export default class extends Controller {
  static targets = ["highlightStart", "highlightEnd", "highlight", "preview"]
  static values = { preview: String }

  connect() {}

  update() {
    const indices = this.getSelectionOffsets()
    const highlight = window.getSelection().toString()

    this.highlightStartTarget.value = indices['start']
    this.highlightEndTarget.value = indices['end']
    this.highlightTarget.value = highlight
    this.updatePreview(indices)
  }

  updatePreview(indices) {
    const params = new URLSearchParams()
    params.set('highlight_start', indices['start'])
    params.set('highlight_end', indices['end'])
    this.previewTarget.src = this.previewValue + `?${params.toString()}`
  }

  getSelectionOffsets() {
    const selection = window.getSelection()
    if (!selection.rangeCount) return { start: 0, end: 0 } // No selection made

    const range = selection.getRangeAt(0)
    const selectedText = range.toString()
    const preCaretRange = range.cloneRange()

    // Select all contents up to the start of the range
    preCaretRange.selectNodeContents(range.startContainer.parentNode)
    preCaretRange.setEnd(range.startContainer, range.startOffset)

    // Trim leading whitespace and duplicate whitespace from selection
    const cleanString = preCaretRange.toString().trimStart().replace("\n", ' ').replace(/\s+/g, ' ')

    const start = cleanString.length
    const end = start + selectedText.length

    return { start, end }
  }

}
