import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="overlay"
export default class extends Controller {
  connect() {}

  show() {
    this.element.classList.add("z-50")
    this.element.classList.remove("hidden")
  }

  hide() {
    this.element.classList.remove("z-50")
    this.element.classList.add("hidden")
  }
}
