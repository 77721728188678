import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="scroll-reveal"
export default class extends Controller {
  static targets = ['image', 'text']
  static debounces = ['update']

  connect() { this.update() }

  update() {
    if(this.scrollHeight > 3 && this.scrollHeight < 80) {
      this.imageTarget.classList.replace('fade-in', 'fade-out')
      this.textTarget.classList.replace('fade-out', 'fade-in')
    } else {
      this.imageTarget.classList.replace('fade-out', 'fade-in')
      this.textTarget.classList.replace('fade-in', 'fade-out')
    }
  }

  get scrollHeight() {
    const totalHeight = document.documentElement.scrollHeight
    const currentScroll = window.scrollY

    return (currentScroll / totalHeight) * 100
  }
}
