import { Controller } from "@hotwired/stimulus"
import * as THREE from 'three'

// Connects to data-controller="star-field"
export default class extends Controller {
  static values = { paths: Array }

  connect() {
    this.mouseX = 0
    this.mouseY = 0

    document.addEventListener("mousemove", (e) => {
      this.mouseX = e.clientX
      this.mouseY = e.clientY
    })

    this.paint()
  }

  paint() {
    this.setTheScene()
    this.addLight()
    this.setCamera()
    this.addStars()

    requestAnimationFrame(this.render.bind(this))
  }

  render(time) {
    if (this.resizeRendererToDisplaySize(this.renderer)) {
      const canvas = this.renderer.domElement
      // changing the camera aspect to remove the strechy problem
      this.camera.aspect = canvas.clientWidth / canvas.clientHeight
      this.camera.updateProjectionMatrix()
    }

    this.stars.forEach(star => {
      star.points.position.x = this.mouseX * star.move
      star.points.position.y = this.mouseY * star.move * -1
    })

    this.renderer.render(this.scene, this.camera)

    // loop
    requestAnimationFrame(this.render.bind(this))
  }

  addStars() {
    const starsT1 = new THREE.Points(this.geometrys[0], this.materials[0])
    const starsT2 = new THREE.Points(this.geometrys[1], this.materials[1])
    const starsT3 = new THREE.Points(this.geometrys[2], this.materials[2])
    const starsT4 = new THREE.Points(this.geometrys[3], this.materials[3])
    const comets =  new THREE.Points(this.geometrys[4], this.materials[4])

    this.stars = [
      { 'points': starsT1, 'move': 0.00005 },
      { 'points': starsT2, 'move': 0.00005 },
      { 'points': starsT3, 'move': 0.00005 },
      { 'points': starsT4, 'move': 0.00005 },
      { 'points': comets,  'move': 0.00005 }
    ]

    this.stars.forEach(star => { this.scene.add(star.points) })
  }

  setTheScene() {
    const canvas = this.element

    this.renderer = new THREE.WebGLRenderer({ canvas })
    this.renderer.setClearColor(new THREE.Color("#0c0a09"))
    this.scene = new THREE.Scene()
  }

  setCamera() {
    const fov = 75, aspect = 2, near = 1.5, far = 7
    this.camera = new THREE.PerspectiveCamera(fov, aspect, near, far);
    this.camera.position.z = 2;
  }

  addLight() {
    const color = 0xffffff, intensity = 1
    this.light = new THREE.DirectionalLight(color, intensity)
    this.light.position.set(-1, 2, 4)
    this.scene.add(this.light)
  }

  get materials() {
    const loader = new THREE.TextureLoader()

    return [
      new THREE.PointsMaterial({
        size: 0.05,
        map: loader.load(this.pathsValue[0]),
        transparent: true
      }),
      new THREE.PointsMaterial({
        size: 0.075,
        map: loader.load(this.pathsValue[1]),
        transparent: true,
        color: "#ff00ff"
      }),
      new THREE.PointsMaterial({
        size: 0.075,
        map: loader.load(this.pathsValue[1]),
        transparent: true,
        color: "#00ffff"
      }),
      new THREE.PointsMaterial({
        size: 0.15,
        map: loader.load(this.pathsValue[1]),
        transparent: true,
        color: "#00ffff"
      }),
      new THREE.PointsMaterial({
        size: 0.15,
        map: loader.load(this.pathsValue[2]),
        transparent: true,
        color: "#ffffff"
      })
    ]
  }

  get geometrys() {
    const geometrys = [new THREE.BufferGeometry(), new THREE.BufferGeometry(), new THREE.BufferGeometry(), new THREE.BufferGeometry(), new THREE.BufferGeometry()]

    geometrys[0].setAttribute(
      "position",
      new THREE.BufferAttribute(this.getRandomParticelPos(3500), 3)
    );
    geometrys[1].setAttribute(
      "position",
      new THREE.BufferAttribute(this.getRandomParticelPos(500), 3)
    );
    geometrys[2].setAttribute(
      "position",
      new THREE.BufferAttribute(this.getRandomParticelPos(500), 3)
    );
    geometrys[3].setAttribute(
      "position",
      new THREE.BufferAttribute(this.getRandomParticelPos(100), 3)
    );
    geometrys[4].setAttribute(
      "position",
      new THREE.BufferAttribute(this.getRandomParticelPos(1), 3)
    );
    return geometrys
  }

  getRandomParticelPos(particleCount) {
    const arr = new Float32Array(particleCount * 3)

    for (let i = 0; i < particleCount; i++) {
      arr[i] = (Math.random() - 0.5) * 10
    }

    return arr
  }

  resizeRendererToDisplaySize(renderer) {
    const canvas = renderer.domElement
    const width = canvas.clientWidth
    const height = canvas.clientHeight
    const needResize = canvas.width !== width || canvas.height !== height

    if (needResize) {
      renderer.setSize(width, height, false)
    }
    return needResize
  }
}
