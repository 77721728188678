import { Controller } from "@hotwired/stimulus"
import { useDebounce } from "stimulus-use"

// Connects to data-controller="search"
export default class extends Controller {
  static debounces = ['submit']
  static targets = ['input', 'frame']
  static values = { url: String }

  connect() {
    useDebounce(this)
  }

  submit() {
    this.frameTarget.src = this.urlValue + this.term
  }

  get term() {
    return `?term=${this.inputTarget.value}`
  }
}
