import { Controller } from "@hotwired/stimulus"
import { useTransition } from "stimulus-use"

// Connects to data-controller="toast"
export default class extends Controller {
  static values = { dismissAfter: { type: Number, default: 2000 } }

  connect() {
    useTransition(this, {
      element: this.element,
      enterActive: 'transition ease-out duration-500',
      enterFrom:   'translate-x-[200%] opacity-0',
      enterTo:     'translate-x-0 opacity-100',
      leaveActive: 'transition-opacity ease-in duration-100',
      leaveFrom:   'translate-x-0 opacity-100',
      leaveTo:     'opacity-0'
    })

    this.open()

    if (this.dismissAfterValue > 0) {
      setTimeout(() => { this.dismiss() }, this.dismissAfterValue)
    }
  }

  close() {
    this.leave()
  }

  open() {
    this.enter()
  }

  async dismiss() {
    await this.leave()
    this.element.remove()
  }
}
