import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select"
// Connects to data-controller="icon-select"
export default class extends Controller {
  static values = { options: Array, selected: Array }

  connect() {
    this.select = new TomSelect(this.element, this.config)
  }

  get config() {
    return {
      valueField: 'id',
      searchField: 'title',
      options: this.optionsValue,
      items: this.selectedValue,
      render: {
        option: function(data, escape) {
          return `
            <div>
              <span><i class="fas ${data.id}"></i>  ${escape(data.title)} </span>
            </div>
          `
        },
        item: function(data, escape) {
          return `<div><span><i class="fas ${data.id}"></i> ${escape(data.title)}</div>`
        }
      }
    }
  }
}
