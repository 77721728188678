import "@hotwired/turbo-rails"
import "./controllers"
import "@github/relative-time-element"
import "tom-select"
import "@fortawesome/fontawesome-free/js/all"
import "trix"
import "@rails/actiontext"
import AOS from 'aos'
import 'aos/dist/aos.css'
import * as ActiveStorage from "@rails/activestorage"

ActiveStorage.start()

document.addEventListener('DOMContentLoaded', function() {
  AOS.init({ mirror: true })
})
